<template>
  <div class="checkWork"  v-loading.fullscreen.lock="fullscreenLoading">
    <div class="checkWork-con" v-loading="true"
            element-loading-text=""
            element-loading-spinner=""
            element-loading-background="rgba(0, 0, 0, 0.5)">
        <div class="students" :style="studentList.length < 7 ? 'display: flex;align-content: center;' : ''">
            <div class="studentDetails" :style="studentList.length < 7 ? 'display: flex;justify-content: center;margin-bottom: 300px' : ''">
                <div v-for="(item,index) in studentList" :key="item.id" class="studentsItem"> 
                    <div class="stu" @click="checkStu(index)" :style="item.isCheck ? item.isCheckBorder : item.unCheckBorder" :class="item.status === 3 || item.status === 5 ? 'noAttendanceForStu' : item.status === 0 || item.status === 1 ? 'isAttendanceForStu' : item.status === 2 ? 'leaveForStu' : ''">
                        <div class="avatar" >
                            <img :src='item.stuImageUrl? item.stuImageUrl  : item.stuGender === 0 ? boyAva : girlAva' alt="学生头像" class="stuImageUrl " :class="item.status ? 'isOpacity' : 'noOpacity'" >
                        </div>
                        <div class="stuName" :class="item.status === 3 || item.status === 5 ? 'noAttendanceForStuName' : item.status === 0 || item.status === 1 || item.status === 2 ? 'isAttendanceForStuName' : ''">{{item.stuName}}</div>
                        <div class="checkWorkContainer">
                            <div class="checkWorkType" :class="item.status === 3 || item.status === 5 ? 'noAttendance' : item.status === 0 || item.status === 1 ? 'isAttendance' : item.status === 2 ? 'leave' : ''">
                                {{item.status === 3 || item.status === 5 ? '未考勤' : item.status === 0 || item.status === 1 ? '我到啦' : item.status === 2 ? '请假' : ''}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="leftArrow"  @click="pageUp" v-if="pageTotal > 0 && page > 1">
                    <img :src='left' alt="上一页" class="leftArrowImg" style="border-radius: 50%;">
                </div>
                <div class="rightArrow" @click="nextPage" v-if="pageTotal > 0 && page < pageTotal">
                    <img :src='right' alt="下一页" class="rightArrowImg" style="border-radius: 50%;">
                </div>
            </div>
            <div class="opera">
                <div class="operation">
                    <span  :class="isSelectAll ? 'selAllButtonOne' : 'buttonOne'" @click="select"><span :class="isSelectAll ? 'selAllBtnOne' : 'btnOne'">{{selectTitle}}</span></span>
                    <span class="buttonTwo" :class="isSelect ? 'isCheck' : 'noCheck'" @click="stuCheck"><span class="btnOne">确认出勤</span></span>
                    <span class="buttonThree" :class="isSignIn ? 'isCheck' : 'noCheck'" @click="classBegin"><span class="btnOne">{{btnTitle}}</span></span>
                    <!-- <span class="buttonFour" :class="noBody ? 'noCheck' : 'isCheck'" @click="notYet"><span class="btnOne">全员未到</span></span> -->
                </div>
            </div>
        </div>
    </div>
    <el-dialog title="提示" :visible.sync="checkDialogVisible" :modal-append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="sucIcon"></div>
                <div class="sucTip">
                    <div class="sucTipMessage">
                         <div class="msgOne">{{sucTipMessageOne}}</div>
                         <div class="msgTwo">{{sucTipMessageTwo}}<span>！</span></div>
                         <div class="msgThree">{{sucTipMessageThree}}<span style="display: inline-block;margin-left: 10px">？</span></div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="classBeginDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="sucClassTip">
                    <div class="sucTipMessage">
                         <div class="beginClassTipOne">{{beginClassTipOne}}</div>
                         <div class="beginClassTipTwo">{{beginClassTipTwo}}<span>？</span></div>
                    </div>
                </div>
                <div class="operation">
                    <div class="goBack" @click="goBack">继续签到</div>
                    <div class="sure" @click="sure">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="notYetDialogVisible"  :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="notYetTip">
                    <div class="sucTipMessage">
                         <div class="notYetTipOne">{{notYetTipOne}}</div>
                    </div>
                    <el-form ref="checkForm" :model="checkForm" :rules="checkRules" class="login-form">
                        <el-form-item prop="password">
                            <el-input v-model="checkForm.password" auto-complete="off" placeholder="请输入登录密码验证" :type="inputType" @focus="onFocus"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="operation">
                    <div class="goBack" @click="notYetCancel">取消</div>
                    <div class="sure" @click="notYetsure" v-loading.fullscreen.lock="loading">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
  
</template>

<script>
const sha512 = require("js-sha512");
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "checkWork",
  
  data() {
    return {
        inputType: 'text',
        sucTipMessageOne: '签到成功',
        sucTipMessageTwo: '快和老师一起开启学习旅程吧',
        sucTipMessageThree: 'Are you ready',
        beginClassTipOne: '请确认教室中学员',
        beginClassTipTwo: '是否完成签到',
        notYetTipOne: '确定全员未到后将自动下课，并顺延调课',
        btnTitle: '开始上课',
        checkDialogVisible: false,
        classBeginDialogVisible: false,
        girlAva: require('../../assets/image/stuGirl.png'),
        boyAva: require('../../assets/image/stuBoy.png'),
        left: require('../../assets/image/left.png'),
        right: require('../../assets/image/right.png'),
        //"状态 0 出勤  1 迟到  2 请假 3 旷课 5 未签到"
        studentList: [],
        paikeId: '',
        fullscreenLoading: false,
        loading: false,
        total: null,
        pageSize: 12,
        page: 1,
        pageTotal: null,
        resultList: [],
        allStuList: [],
        selectTitle: '全选学员',
        isSelectAll: false,
        notYetDialogVisible: false,
        checkForm: {
            password: ''
        },
        checkRules: {
            password: [
                { required: true, trigger: "blur", message: "密码不能为空" },
            ],
        }
    };
  },
 
  created() {
      this.getCoursePaikeId();
    // this.getPaikeId().then(() => {
    //     this.getStudentListInStudy();
    //     this.getStatus();
    // }).catch(() => {
    //     // if(!this.paikeId){
    //     //     this.$router.push({path: "home"});               
    //     // }else{
    //     //     this.getStudentListInStudy();
    //     //     this.getStatus();
    //     // }
    //     this.getStudentListInStudy();
    //     this.getStatus();
    // });
  },
  computed:{
      isSelect(){
        let isCheckStuList = this.studentList.filter(item => {
            return item.isCheck
        });
        return isCheckStuList.length > 0
      },
      isSignIn(){
        let isSignInList = this.allStuList.filter(item => {
            return item.status === 0 || item.status === 1
        });
        return isSignInList.length > 0
      },
      noBody(){
        let notYet = this.allStuList.some(item => {
            return item.status === 0 || item.status === 1
        });
        return notYet
      }
  },
  methods: {
    onFocus(){
      this.inputType = 'password'
    },
    async getCoursePaikeId(){
        this.fullscreenLoading = true;
        // const that = this;
        // return new Promise(function(resolve,reject){
        //     page.getCurrDayOnClass({}, res => {
        //         if(res.status === 1) {
        //             if(res.data && res.data.courseName){
        //                 that.paikeId_cur = res.data.paikeId;
        //                 resolve()
        //             }else{
        //                 that.paikeId_cur = '';
        //                 reject()
        //             }   
        //         }else {
        //             Message.error(res.message);
        //         }
        //         that.fullscreenLoading = false;
        //     });
        // })
        await page.getCoursePaikeId('', res => {
            console.log(res);
            if(res.status === 1) {
                if(res.data && res.data.courseName){
                    this.paikeId = res.data.scheduleId;
                    this.getStudentListInStudy();
                    this.getStatus();
                }else{
                    Message.error('未查询到排课Id');
                }
            }else {
                Message.error(res.message);
            }
            this.fullscreenLoading = false;
        });
    },
    getStatus(){
        let id = this.paikeId  ? this.paikeId : '0';
        page.goToPage(id, res => {
            if(res.status === 1) {
                if(res.data === 'index'){
                    return this.$router.replace({name: 'home'})
                }
                if(res.data === 'class'){
                    this.btnTitle = '继续上课'
                }else {
                    this.btnTitle = '开始上课'
                }
                
            }else {
                Message.error(res.message);
            }
        });    
    },
    getStudentListInStudy(){
        this.fullscreenLoading = true;
        let id = this.paikeId;
        page.getStudentListInStudy(id, res => {
            console.log(res)
            if(res.status === 1) {
                let newList= res.data.map(item => ({
                    ...item,
                    isCheck: false,
                    isCheckBorder: 'border: 5px solid #42D78C;box-shadow: #42D78C 0px 0px 8px 8px;',
                    unCheckBorder: 'border: 1px solid #fff'
                }));
                this.allStuList = newList;
                this.resultList = [];
                this.total = newList.length;
                for (var i = 0, j = newList.length; i < j; i += this.pageSize) {
                    this.resultList.push(newList.slice(i, i + this.pageSize));
                }
                this.pageTotal = this.resultList.length;
                this.studentList = this.resultList.length > 0 ? this.resultList[this.page-1] : [];
                this.fullscreenLoading = false;
            }else {
                this.fullscreenLoading = false;
                Message.error(res.message);
            }
        });
    },
    async stuCheck(){
        let isCheckStuList = this.allStuList.filter(item => {
            return item.isCheck
        });
        if(isCheckStuList.length === 0) return false
        let noSignInList = isCheckStuList.filter(item => {
            return item.status === 3 || item.status === 5 || item.status === 2
        });
        if(noSignInList.length === 0) return false
        this.fullscreenLoading = true;
        await this.stuSignIn(noSignInList,null);
        await this.getStudentListInStudy();
        this.checkDialogVisible = true
        setTimeout(() => {
            this.checkDialogVisible = false
        },500)
    },
    // 全选or反选
    select(){
        if(!this.isSelectAll){
            this.allStuList.forEach(item => {
                if(item.status === 3 || item.status === 5 || item.status === 2){
                    item.isCheck = true
                }
            })
            let hasSelect = this.allStuList.some(item => {
                return item.isCheck
            })
            if(hasSelect){
                this.selectTitle = '取消全选'
                this.isSelectAll = true
            }
        }else{
           this.allStuList.forEach(item => {
                item.isCheck = false
            })
            this.selectTitle = '全选学员'
            this.isSelectAll = false
        }
    },
    classBegin(){
        let hasCheck = this.allStuList.some(item => {
            return item.status === 0 || item.status === 1
        })
        if(hasCheck){
            this.classBeginDialogVisible = true;
        }
    },
    goBack(){
        this.classBeginDialogVisible = false;
    },
    sure(){
        let id = this.paikeId;
        page.aflpClassTeacherSignInEnsure(id, res => {
            if(res.status === 1) {
                this.classBeginDialogVisible = false;
                this.$router.replace({name: 'attendClass'})
            }else {
                Message.error(res.message);
            }
        });
    },
    stuSignIn(noSignInList,status){
        const basicIdList = noSignInList.map(item => item['basicId'])
        const reduceTypeList = noSignInList.map(item => item['reduceType'])
        const makeUpIdList = noSignInList.map(item => item['makeUpId'])
        const stuTypeList = noSignInList.map(item => item['stuType'])
        const params = {
            schedulingId: this.paikeId,
            status: status,
            stuBasicId: basicIdList.join(','),
            reduceType: reduceTypeList.join(','),
            makeUpId: makeUpIdList.join(','),
            stuType: stuTypeList.join(','),
        }
        return new Promise(resolve => {
            page.studentSignIn(params, res => {
                if(res.status === 1) {
                    this.selectTitle = '全选学员'
                    this.isSelectAll = false
                    resolve();
                }else {
                    Message.error(res.message);
                    this.fullscreenLoading = false;
                }
            });
        });
    },
    // 选择学员
     checkStu(index){
         if(this.studentList[index].status === 3 || this.studentList[index].status === 5 || this.studentList[index].status === 2){
            let isCheck = this.studentList[index].isCheck
            this.studentList[index].isCheck = !isCheck;
         }
     },
     pageUp(){
          this.fullscreenLoading = true;
          this.page -=1;
          this.studentList = this.resultList[this.page-1];
          setTimeout(() => {
              this.fullscreenLoading = false;
          },300)
      },
      nextPage(){
          this.fullscreenLoading = true;
          this.page +=1;
          this.studentList = this.resultList[this.page-1];
          setTimeout(() => {
              this.fullscreenLoading = false;
          },300)
      },
      notYet(){
        let notYet = this.allStuList.some(item => {
            return item.status === 0 || item.status === 1
        });
        if(notYet) return false
        this.notYetDialogVisible = true;
      },
      notYetCancel(){
          this.notYetDialogVisible = false;
          this.$refs.checkForm.resetFields();
      },
      notYetsure(){
        //   this.loading = true;
        //   let id = this.paikeId ? this.paikeId : this.paikeId_cur
        //   page.emptyClass(id, async res => {
        //     if(res.status === 1) {
        //         this.notYetDialogVisible = false;
        //         this.$router.replace({
        //             path: "home",
        //         });
        //     }else {
        //         Message.error(res.message);
        //     }
        //     this.loading = false;
        // });
        this.$refs.checkForm.validate((valid) => {
            if (valid) {
                let timestamp = new Date().getTime();
                let pwd1 = sha512(`${this.checkForm.password}:yzyx`);
                let pwd2 = sha512(`${pwd1}${timestamp}`);
                this.loading = true;
                let id = this.paikeId;
                const params = {
                    schedulingId: id,
                    pwd: pwd2,
                    timestamp: timestamp,
                }
                page.emptyClass(params, async res => {
                    if(res.status === 1) {
                        this.notYetDialogVisible = false;
                        this.$router.replace({
                            path: "home",
                        });
                    }else {
                        Message.error(res.message);
                    }
                    this.loading = false;
                });
            }
        });    
      },
  },
};
</script>

<style lang="less" scoped>
.checkWork {
    height: 100vh;
    width: 100%;
    z-index: 0;
    .checkWork-con{
        overflow-x: hidden; /*x轴禁止滚动*/
        overflow-y: scroll;/*y轴滚动*/
        height: 100%;
        background: url("../../assets/image/guide.png") center/cover no-repeat;
        
    }
    .checkWork-con::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    }
    .students{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 15px 40px 0px 40px;
        z-index: 10;
        height: 100%;
        .studentDetails{
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            margin-left: 5%;
            z-index: 10;
            .noAttendanceForStu{
                background: #fff;
            }
            .noAttendanceForStuName{
                color: #333333;
            }
            .isAttendanceForStu{
                background: #2CBD74;
            }
            .isAttendanceForStuName{
                color: #fff;
            }
            .leaveForStu{
                background: #DC5310;
            }
        }
        .studentsItem{
            flex: 0 0 15%;
            box-sizing: border-box;
            margin-right: 2%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .info{
                display: flex;
                height: 51px;
                width: 100%;
                justify-content: center; /* 水平居中 */
                .signInOp{
                    width: 80%;
                }
                .opBtn{
                    width: 80%;
                    /deep/ .el-button{
                        width: 100%;
                        border-radius: 25px;
                    }
                }
            }
            .stuName{
                font-size: 26px;
                letter-spacing: 8px;
                line-height: 80px;
                text-align: center;
            }
        }
        .stu{
            width: 240px;
            height: 300px;
            background: #fff;
            border-radius: 60px;
            cursor: pointer;
            border: none !important;
            // box-shadow: 0px 5px #36A689;
            .avatar{
                position: relative;
                .stuImageUrl {
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                    margin-top: 30px;
                    margin-left: 65px;
                }
            }
            .checkWorkContainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .checkWorkType{
                    width: 155px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border-radius: 23px;
                    font-size: 16px;
                }
                .noAttendance{
                    border: 1px solid #B5B5B5;
                    color: #7C7C7C;
                }
                .isAttendance{
                    border: 1px solid #fff;
                    color: #fff;
                    background: #2CBD74;
                }
                .leave{
                    border: 1px solid #fff;
                    color: #fff;
                    background: #DC5310;
                }
            }
        }
        .stu:hover{
            transition: all  0.5s;
        }
        .opera{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            .operation{
                width: 100%;
                height: 140px;
                position: fixed;
                bottom: 20px;
                z-index: 10;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .buttonOne{
                    width: 200px;
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 6%;
                    margin:0 3%;
                    cursor: pointer;
                    background: #42D78C;
                    box-shadow: 0px 5px #2CBD74;
                    .btnOne{
                        color: #fff;
                        font-weight: 700;                           
                        font-family: PingFangSC-Semibold, PingFang SC;                         
                        font-size: 20px;
                    }
                }
                .selAllButtonOne{
                    width: 200px;
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 6%;
                     margin:0 3%;
                    cursor: pointer;
                    background: #FAE9C2;
                    box-shadow: 0px 5px #9D7635;
                    .selAllBtnOne{
                        color: #9D7635;
                        font-weight: 700;                           
                        font-family: PingFangSC-Semibold, PingFang SC;                         
                        font-size: 20px;
                    }
                }
                .buttonTwo{
                    width: 200px;
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 6%;
                     margin:0 3%;
                    .btnOne{
                        color: #fff;
                        font-weight: 700;                           
                        font-family: PingFangSC-Semibold, PingFang SC;                         
                        font-size: 20px;
                    }
                }
                .isCheck{
                    background: #42D78C;
                    box-shadow: 0px 5px #2CBD74;
                    cursor: pointer;
                }
                .noCheck{
                    background: #BBBABA;
                    box-shadow: 0px 5px #A3A2A2;
                }
                .buttonThree{
                    width: 200px;
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 6%;
                     margin:0 3%;
                    .btnOne{
                        color: #fff;
                        font-weight: 700;                           
                        font-family: PingFangSC-Semibold, PingFang SC;                         
                        font-size: 20px;
                    }
                }
                .buttonFour{
                    width: 200px;
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .btnOne{
                        color: #fff;
                        font-weight: 700;                           
                        font-family: PingFangSC-Semibold, PingFang SC;                         
                        font-size: 20px;
                    }
                }
            }
        }
        .leftArrow{
            z-index: 10;
            position: absolute;
            top: 35%;
            left: 1%;
            cursor: pointer;
        }
        .rightArrow{
            z-index: 10;
            position: absolute;
            top: 35%;
            right: 1%;
            cursor: pointer;
        }
        .leftArrowImg{
            width: 80px;
            height: 80px;
        }
        .rightArrowImg{
            width: 80px;
            height: 80px;
        }
    }
    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 760px;
        height: 475px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 630px;
            height: 410px;
            background: #FFFCFD;
            border-radius: 135px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 400px;
                height: 110px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucIcon{
                position: absolute;
                left: 50%;
                top: 27%;
                width: 170px;
                height: 183px;
                background: url("../../assets/image/checkSuc.gif") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucTip{
                position: absolute;
                top: 52%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .msgOne{
                        letter-spacing:8px;
                    }
                    .msgTwo{
                        letter-spacing:8px;
                        margin-top: 10px;
                    }
                    .msgThree{
                        word-spacing: 10px;
                        margin-top: 30px;
                        color: #2CBD74;
                    }
                    .beginClassTipOne{
                        letter-spacing:8px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                    .notYetTipOne{
                        letter-spacing:4px;
                    }
                    .notYetTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .sucClassTip{
                position: absolute;
                top: 20%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .beginClassTipOne{
                        letter-spacing:8px;
                        margin-bottom: 30px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .notYetTip{
                position: absolute;
                top: 26%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .notYetTipOne{
                        letter-spacing:8px;
                        margin-bottom: 30px;
                    }
                    .notYetTipTwo{
                        letter-spacing:8px;
                    }
                }
                  .el-form-item {
                    margin-bottom: 36px;
                    width: 100%;
                    width: 400px;
                    /deep/.el-input {
                    .el-input__inner {
                        height: 55px;
                        line-height: 55px;
                        border-radius: 48px;
                        padding-left: 40px;
                        font-size: 16px;
                        margin-left: 100px;
                        &::placeholder {
                            font-size: 16px;
                            font-family: Noto Sans S Chinese;
                            font-weight: 400;
                            color: #939393;
                        }
                    }
                    }
                    /deep/ .el-form-item__error{
                    font-size: 16px;
                    left: 120px
                    }
                }
            }
            .operation{
                position: absolute;
                left: 50%;
                top: 75%;
                width: 500px;
                height: 110px;
                transform: translateY(-50%) translateX(-50%);
                display: flex;
                flex-flow:  row nowrap;
                justify-content: space-between;
                align-items: center;
                letter-spacing:3px;
                .goBack{
                    width: 200px;
                    height: 60px;
                    border: 1px solid #2CBD74;
                    border-radius: 42px;               
                    color: #2CBD74;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
                .sure{
                    width: 200px;
                    height: 60px;
                    background: #2CBD74;
                    border-radius: 42px;
                    color: #FFFCFD;;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
   
    
    /deep/ .el-loading-mask{
        z-index: 1;
    }
    /deep/ .el-loading-spinner{
        display: none;
    }
    /deep/ .el-button {
        width: 128px;
        height: 51px;
        font-size: 18px;
    }
    /deep/ .el-dialog__header{
        display: none;
    }
    /deep/ .el-button-group>.el-button:last-child{
        border-radius: 0 25px 25px 0;
        width: 40%;
    }
    /deep/ .el-button-group>.el-button:first-child{
        border-radius: 25px 0 0 25px;
        width: 60%;
    }
    .studentsItem:nth-child(6){
        margin-right: 0%;
    }
    .studentsItem:nth-child(12){
        margin-right: 0%;
    }
    .studentsItem:nth-child(18){
        margin-right: 0%;
    }
    
}
</style>
<style lang="scss" scoped>
    .students::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    }  
</style>